import React, { useState } from 'react';
import { addresses } from 'UI/components/CompanyLocation/utils/data';
import LinkWrapper from 'UI/components/LinkWrapper';
import AccordionItem from 'UI/components/AccordionItem';
import styles from './styles.module.scss';
import { mainContent } from '../utils/data';

const FooterCenter = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (id) => {
    setActiveIndex(id);
  };

  return (
    <section className={styles['footer-body']}>
      <div className={styles['body-top']}>
        <ul className={styles['country-list']}>
          {addresses.map((country) => (
            <li
              key={country.title}
              className={styles['country-item']}
            >
              {country?.title && (
                <p className={styles['item-title']}>{country.title}</p>
              )}
              {country?.text && (
                <p className={styles['item-text']}>
                  {(country?.text || [])
                    ?.map((text) => text)
                    .join(', ')}
                </p>
              )}
              {country?.path && (
                <p className={styles['item-path']}>{country.path}</p>
              )}
            </li>
          ))}
        </ul>
      </div>
      <div className={styles['body-bottom']}>
        <ul className={styles['route-list']}>
          {mainContent.map((route) => (
            <div
              key={route.type}
              className={styles['route-item']}
            >
              <h4 className={styles['route-item-title']}>{route.title}</h4>
              <ul className={styles['link-list']}>
                {(route.links || []).map((link) => (
                  <li key={link.path}>
                    <LinkWrapper
                      path={link.path}
                      className={styles['item-link']}
                    >
                      {link.subtitle}
                    </LinkWrapper>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </ul>
        <ul className={styles['route-list-accordion']}>
          {mainContent.map((route, id) => (
            <AccordionItem
              key={id}
              id={id}
              title={route.title}
              isOpen={activeIndex === id}
              handleClick={() => handleClick(id)}
              className={styles['accordion-item']}
              buttonClassName={styles['accordion-button']}
              isOpenClassName={styles['accordion-is-open']}
            >
              <ul className={styles['link-list']}>
                {(route.links || []).map((link) => (
                  <li key={link.path}>
                    <LinkWrapper
                      path={link.path}
                      className={styles['item-link']}
                    >
                      {link.subtitle}
                    </LinkWrapper>
                  </li>
                ))}
              </ul>
            </AccordionItem>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default FooterCenter;
