import LinkWrapper from 'UI/components/LinkWrapper';
import Svg from 'UI/components/Svg';
import SubscribeForm from 'UI/components/SubscribeForm';
import styles from './styles.module.scss';

const FooterHeader = () => (
  <section className={styles['footer-header']}>
    <div className={styles.left}>
      <LinkWrapper
        path="/"
        className={styles.logo}
      >
        <Svg type="whiteLogo" />
        <Svg type="whiteYellowText" />
      </LinkWrapper>
      <p className={styles['normal-text']}>
        Software innovation powerhouse born to take your business to the top!
      </p>
    </div>
    <div className={styles.right}>
      <p className={styles['heading-text']}>Join our newsletter</p>
      <p className={styles['normal-text']}>We’ll send you a nice letter once per week. No spam.</p>
      <SubscribeForm />
      <p className={styles['small-text']}>By providing your email, you are consenting to receive communications.</p>
    </div>
  </section>
);

export default FooterHeader;
