import { useState } from 'react';
import { SUBSCRIPTION_CASH_KEY, useSubscribeMutation } from 'redux/apis/dataSending';
import { validateEmail } from 'utils/helper';
import { useRouter } from 'next/router';
import cn from 'classnames';
import styles from './styles.module.scss';

const SubscribeForm = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const [
    subscribe,
    {
      isLoading, data: { subscriptionEmail } = {},
    },
  ] = useSubscribeMutation({ fixedCacheKey: SUBSCRIPTION_CASH_KEY });

  const {
    pathname,
  } = useRouter();

  const handleChange = (event) => {
    const { value } = event.target;
    setEmail(value);

    if (value?.length && !validateEmail(value)) {
      setError('Please enter a valid email address.');
    } else {
      setError('');
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (isLoading) {
      return;
    }

    subscribe({ email, pathname });
  };

  return (
    <div className={styles.form}>
      {subscriptionEmail ? (
        <p>We have received your request. We will back in a flash⚡.</p>
      ) : (
        <>
          <div className={styles['input-wrapper']}>
            <input
              type="text"
              value={email}
              onChange={handleChange}
              placeholder="Enter your email"
              className={cn(styles.input, { [styles.invalid]: error })}
            />
            {error && (
              <p className={styles.error}>
                {error}
              </p>
            )}
          </div>
          <button
            type="button"
            onClick={handleSubmit}
            disabled={isLoading}
            className={styles.button}
          >
            Subscribe
          </button>
        </>
      )}

    </div>
  );
};

export default SubscribeForm;
