import { useRouter } from 'next/router';
import Svg from 'UI/components/Svg';
import LinkWrapper from 'UI/components/LinkWrapper';
import { socialMedia } from '../utils/data';
import styles from './styles.module.scss';

const FooterFoot = () => {
  const { pathname } = useRouter();

  return (
    <section className={styles['footer-foot']}>
      <div className={styles['social-links']}>
        {socialMedia.map(({
          iconLight: icon,
          title,
          link,
        }) => link && (
          <LinkWrapper
            className={styles['social-link']}
            key={`networks/${link}`}
            path={link}
            googleAnalyticProps={{
              action: 'Click',
              category: 'Social',
              label: pathname,
              data: title,
            }}
            isSocialLink
          >
            <Svg
              type={icon}
              className={styles.icon}
            />
          </LinkWrapper>
        ))}
      </div>
      <div className={styles.bottom}>
        <p>© All right reserved. Yellow 2022</p>
        <div className={styles['privacy-policy']}>
          <p>Privacy Policy</p>
          <p>Terms & Privacy</p>
        </div>
      </div>
    </section>
  );
};

export default FooterFoot;
